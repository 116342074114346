import { AiFillHome, AiOutlineUser } from "react-icons/ai";
import {
  MdOutlineDescription,
  MdOutlinePriceCheck,
  MdPlace,
} from "react-icons/md";
import { FaUser, FaWallet } from "react-icons/fa";

export const navItems = [
  {
    name: "Resources",
    items: [{ name: "Home", path: "", src: <AiFillHome size={20} /> }],
  },
  {
    name: "Reserves",
    items: [
      {
        name: "Proof Of Reserve",
        path: "proof-of-reserve",
        src: <MdPlace size={20} />,
      },
    ],
  },
  {
    name: "account",
    items: [
      { name: "Sign Up", path: "auth/register", src: <FaUser size={20} /> },
      { name: "Sign In", path: "auth/login", src: <AiOutlineUser size={20} /> },
    ],
  },
  {
    name: "products",
    items: [
      {
        name: "Wallet",
        path: "wallet",
        src: <FaWallet size={20} />,
      },
      {
        name: "Exchange",
        path: "exchange",
        src: <MdOutlinePriceCheck size={20} />,
      },
    ],
  },
  {
    name: "about",
    items: [
      {
        name: "About",
        path: "about",
        src: <MdOutlineDescription size={20} />,
      },
    ],
  },
];

export const withdrawals = [
  {
    name: "Liberty Rothstein",
    type: "Bitcoin",
    amount: 5000,
    status: true,
    symbol: "₹",
  },
  {
    name: "Teodoro Scalese",
    type: "Ethereum",
    amount: 18000,
    status: true,
  },
  {
    name: "Karlyn Schettig",
    type: "Bitcoin",
    amount: 17500,
    status: false,
    symbol: "$",
  },
  {
    name: "Kerrie Juckett",
    type: "Ethereum",
    amount: 50000,
    status: false,
    symbol: "₹",
  },
  {
    name: "Juliet Austin",
    type: "USDT",
    amount: 20000,
    status: true,
    symbol: "$",
  },
  {
    name: "Jane Appeal",
    type: "USDT",
    amount: 25000,
    status: true,
    symbol: "₱",
  },
  {
    name: "David Julius",
    type: "Ethereum",
    amount: 30000,
    status: true,
  },
  {
    name: "Jennifer Jane",
    type: "USDT",
    amount: 30000,
    status: false,
    symbol: "₱",
  },
];

export const deposits = [
  {
    name: "Alice Johnson",
    type: "Ethereum",
    status: true,
    amount: 100000,
    symbol: "₹",
  },
  {
    name: "Bob Smith",
    type: "USDT",
    status: false,
    amount: 5000000,
    symbol: "₱",
  },
  {
    name: "Hannah Clark",
    type: "Ethereum",
    status: true,
    amount: 750000,
  },
  {
    name: "Charlie Brown",
    type: "USDT",
    status: false,
    amount: 1200000,
  },
  {
    name: "David Lee",
    type: "Bitcoin",
    status: true,
    amount: 2000000,
  },
  {
    name: "Eve Davis",
    type: "Bitcoin",
    status: true,
    amount: 3000000,
    symbol: "₱",
  },
  {
    name: "Frank White",
    type: "Ethereum",
    status: false,
    amount: 900000,
    symbol: "$",
  },
  {
    name: "Grace Taylor",
    type: "Bitcoin",
    status: true,
    amount: 6000000,
    symbol: "₹",
  },
];
