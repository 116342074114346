import React from "react";
import Motion from "../../apps/Motion";
import MotionRight from "../../apps/MotionRight";

const Hero = ({ title, paragraph }) => {
  return (
    <div className="about_hero_sect">
      <Motion>
        <p className="home_paragraph">{paragraph}</p>
      </Motion>
      <MotionRight>
        <h1 className="home_title bold_header_text">{title}</h1>
      </MotionRight>
    </div>
  );
};

export default Hero;
