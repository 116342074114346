import React, { useState } from "react";
import "./ResetPassword.css";
import { Button } from "../../components";
import logo from "../../assets/profits.svg";
import axios from "axios";
import { useGlobalContext } from "../../context/context";
import { useForm } from "react-hook-form";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

const ForgetPassword = () => {
  const { baseUrl } = useGlobalContext();
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleResetPassword = (data) => {
    data.resetToken = id;
    setLoading(true);
    axios
      .post(`${baseUrl}auth/reset`, data)
      .then((data) => {
        toast.success("Password Changed Successfully");
        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div className="resetpassword_sect">
      <div className="resetpassword_header">
        <div className="resetpassword_header_image_container">
          <img src={logo} alt="logo" />
        </div>
        <div className="resetpassword_btn_sect">
          <Button
            title={"Have an Account?"}
            background={"transparent"}
            color={"#001e88"}
            fontSize={14}
            height={50}
            width={200}
            action={() => navigate("/auth/login")}
          />
          <Button
            title={"Sign In"}
            background={"#001e88"}
            color={"#fff"}
            fontSize={14}
            height={50}
            width={200}
            action={() => navigate("/auth/register")}
          />
        </div>
      </div>
      <div className="resetpassword_hero">
        <h2>Reset Password?</h2>
        <p>Please enter your new password and confirm to proceed.</p>
        <form
          style={{ marginBottom: 60 }}
          className="reset_form"
          onSubmit={handleSubmit((data) => handleResetPassword(data))}
        >
          <p>Enter new Password to Reset Password</p>
          <input
            style={{ marginBottom: 30 }}
            type="password"
            placeholder="New Password"
            {...register("password")}
          />
          <p>Enter Confirm Password to Reset Password</p>
          <input
            type="password"
            placeholder="Confirm Password"
            {...register("confirmpassword")}
          />
          <Button
            title={
              loading ? (
                <ColorRing
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                "Reset Password"
              )
            }
            width={"100%"}
            height={55}
            color={"white"}
            background={"#001e88"}
            action={() => ""}
          />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgetPassword;
