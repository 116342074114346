import React from "react";
import "./Exchange.css";
import { Button, Footer, Header } from "../../components";
import key from "../../assets/key.png";
import liqudity from "../../assets/exchange_liquidity.png";
import speed from "../../assets/speed.png";
import trade from "../../assets/trade.png";
import ease from "../../assets/ease.png";
import laptop from "../../assets/web-signup.svg";
import wallet from "../../assets/wallet.png";
import secure from "../../assets/safe.png";
import Motion from "../Motion";
import MotionX from "../MotionX";
import MotionRight from "../MotionRight";
import { useNavigate } from "react-router-dom";

const Exchange = () => {
  const name = "Profitmonitoring";
  const navigate = useNavigate();

  return (
    <section>
      <Header />
      <div className="exchange_hero">
        <Motion>
          <h1 className="home_title">High-performance crypto exchange</h1>
        </Motion>
        <MotionX>
          <p>
            Easy-to-use interface, industry-leading security, deep liquidity
          </p>
        </MotionX>
        <MotionRight>
          <Button
            action={() => navigate("/wallet")}
            title={"View Exchange"}
            color={"white"}
            width={220}
            height={50}
          />
        </MotionRight>
      </div>
      <div className="Exchange_trading_section">
        <MotionX>
          <h1 className="home_title bold_header_text">Trading with {name}</h1>
        </MotionX>
        <MotionRight>
          <p className="dynamic_text">
            Dynamic cryptocurrency trading that puts you in the driver's seat
          </p>
        </MotionRight>
        <div className="exchange_intro_sect">
          <div>
            <Motion>
              <img src={key} alt="" />
            </Motion>
            <MotionRight>
              <p className="home_paragraph">FEES</p>
            </MotionRight>
            <MotionX>
              <p>
                Get rewarded the more you trade. {name} offers a dynamic, tiered
                fee structure that’s highly competitive and carefully designed
                to ensure that you’ll pay lower fees based on your gross trading
                volume.
              </p>
            </MotionX>
          </div>
          <div>
            <MotionX>
              <img src={wallet} alt="" />
            </MotionX>
            <Motion>
              <p className="home_paragraph">API</p>
            </Motion>
            <MotionRight>
              <p>
                The {name} API gives you an easy way to integrate into the
                Exchange platform, allowing you to programmatically manage your
                account, make trades and retrieve market data.
              </p>
            </MotionRight>
          </div>
          <div>
            <MotionX>
              <img src={secure} alt="" />
            </MotionX>
            <Motion>
              <p className="home_paragraph">SECURITY</p>
            </Motion>
            <MotionRight>
              <p>
                {name}'s industry-leading security measures work to keep your
                crypto safe. {name} stores 95% of crypto in “deep freeze” –
                multi-signature wallets protected by many layers of encryption.
              </p>
            </MotionRight>
          </div>
        </div>
      </div>
      <div className="features_sect">
        <div>
          <div className="features_sect_image_container">
            <MotionX>
              <img src={liqudity} alt="" />
            </MotionX>
          </div>
          <div className="exchange_feature_sect">
            <Motion>
              <h1 className="bold_header_text">Liquidity</h1>
            </Motion>
            <MotionRight>
              <p>
                Our liquidity ensures the tightest spreads so that your crypto
                trades are executed as close to the price you want as possible,
                maximising your profits. Liquidity is driven by our low fees,
                high volume and many active traders.
              </p>
            </MotionRight>
          </div>
        </div>
        <div style={{ flexDirection: "row-reverse" }}>
          <div className="features_sect_image_container">
            <MotionRight>
              <img src={ease} alt="" />
            </MotionRight>
          </div>
          <div className="exchange_feature_sect">
            <MotionX>
              <h1 className="bold_header_text">Speed</h1>
            </MotionX>
            <Motion>
              <p>
                Execute trades in microseconds without compromising reliability
                or security. Our low-latency trading platform has all the tools
                you need to make the most of market opportunities, whenever and
                wherever you are.
              </p>
            </Motion>
          </div>
        </div>
        <div>
          <div className="features_sect_image_container">
            <Motion>
              <img src={speed} alt="" />
            </Motion>
          </div>
          <div className="exchange_feature_sect">
            <MotionX>
              <h1 className="bold_header_text">Ease</h1>
            </MotionX>
            <MotionRight>
              <p>
                Enjoy an intuitive user experience designed for smooth,
                efficient trading. Luno is optimised for ease-of-use, offering
                simple order placement and high-quality charting tools to give
                you the best chance of success – whatever your experience level.
              </p>
            </MotionRight>
          </div>
        </div>
        <div style={{ flexDirection: "row-reverse" }}>
          <div className="features_sect_image_container">
            <Motion>
              <img src={trade} alt="" />
            </Motion>
          </div>
          <div className="exchange_feature_sect">
            <MotionRight>
              <h1 className="bold_header_text">Trade on the go</h1>
            </MotionRight>
            <MotionX>
              <p>
                Deposit, withdraw, and trade 24/7 on our mobile apps for Android
                and iOS, so you can experience professional-level crypto
                exchange features on the move.
              </p>
            </MotionX>
          </div>
        </div>
      </div>
      <div className="exchange_download_sect">
        <MotionX>
          <h1 className="home_title">The cryptocurrency market is waiting</h1>
        </MotionX>
        <Motion>
          <p className="home_paragraph">Your trading journey starts now</p>
        </Motion>
        <div className="sign_up_btn">
          <MotionRight>
            <img src={laptop} alt="" />
          </MotionRight>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Exchange;
