import React from "react";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import "./Settings.css";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProfileDetails from "../ProfileDetails/ProfileDetails";
import UpdateProfile from "../UpdateProfile/UpdateProfile";
import { useGlobalContext } from "../../../context/context";

const Settings = () => {
  const [tab, setTab] = useState(1);
  const { userDetails } = useGlobalContext();
  const { firstName, lastName } = userDetails;

  const navigate = useNavigate();

  const toggleSettings = (e) => {
    if (e.target.id === "1") {
      setTab(1);
    } else if (e.target.id === "2") {
      setTab(2);
    }
  };
  return (
    <section className="settings">
      <DashboardHeader pathname={`${firstName}`} />
      <div className="settings_hero_sect">
        <div className="settings_profile_sect">
          <div></div>
          <h2>
            {firstName} {lastName}
          </h2>
        </div>
        <Button
          title={"Update Profile"}
          action={() => navigate("/update-profile")}
        />
      </div>
      <div className="settings_navigation">
        <div className="settings_navs">
          <Button
            title={"Profile"}
            background={`${tab === 1 ? "#071278" : "white"}`}
            color={`${tab === 1 ? "white" : "#071278"}`}
            action={toggleSettings}
            id={1}
          />
          <Button
            title={"Update Profile"}
            background={`${tab === 2 ? "#071278" : "white"}`}
            color={`${tab === 2 ? "white" : "#071278"}`}
            action={toggleSettings}
            id={2}
          />
        </div>
        <div className="settings_content_tabs">
          {tab === 1 ? <ProfileDetails /> : <UpdateProfile setTab={setTab} />}
        </div>
      </div>
    </section>
  );
};

export default Settings;
